<template>
  <b-card id="page--forms" no-body>
    <div>
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div class="mr-1">
            <b-button
              id="create-button"
              variant="primary"
              :class="{
                'icon-disabled': !canCreate,
              }"
              @click="canCreate && create()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip target="create-button">
              {{
                canCreate ? $t('Create') : $t('form-create-not-allowed-text')
              }}
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          class="table-responsive"
          :busy="loading"
          :items="items"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(internalTitle)="{ item }">
            <prozess-resource-name
              :id="`view-row-${item.uuidKey}-link`"
              class="text-nowrap d-inline-block"
              :has-permission="canRead"
              :to="{ name: 'form-view.view', params: { id: item.uuidKey } }"
              :title="item.internalTitle"
            />
            <b-tooltip
              v-if="!canRead"
              placement="left"
              :title="actionTooltip(item, 'Read')"
              :target="`view-row-${item.uuidKey}-link`"
            />
          </template>
          <template #cell(numberOfBlocks)="{ item }">
            <b-badge pill variant="secondary" class="tw-px-4 tw-min-w-16">
              {{ item.numberOfBlocks }}
            </b-badge>
          </template>
          <template #cell(open)="{ item }">
            <b-badge variant="gray" class="tw-px-4">
              {{ item.open ? $t('Open Form') : $t('Login Form') }}
            </b-badge>
          </template>
          <template #cell(numberOfResponses)="{ item }">
            <b-badge pill variant="secondary" class="tw-px-4 tw-min-w-16">
              {{ item.numberOfResponses }}
            </b-badge>
          </template>

          <!-- START: Actions Column -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuidKey}-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !canUpdate,
                }"
                @click="
                  canUpdate &&
                    $router.push({
                      name: 'form-view.edit',
                      params: { id: item.uuidKey },
                    })
                "
              />
              <b-tooltip
                :target="`edit-row-${item.uuidKey}-icon`"
                placement="left"
              >
                {{
                  canUpdate ? $t('Edit') : $t('no-edit-form-permission-text')
                }}
              </b-tooltip>
              <feather-icon
                :id="`delete-row-${item.uuidKey}-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !canDeleteForm(item),
                }"
                @click="confirmRemoval(item)"
              />
              <b-tooltip
                placement="left"
                :title="deleteTooltip(item)"
                :target="`delete-row-${item.uuidKey}-icon`"
              />
              <feather-icon
                :id="`view-submissions-${item.uuidKey}-icon`"
                icon="UsersIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="
                  $router.push({
                    name: 'forms.submissions',
                    params: { id: item.uuidKey },
                  })
                "
              />
              <b-tooltip
                placement="left"
                :title="$t('View submissions')"
                :target="`view-submissions-${item.uuidKey}-icon`"
              />
            </div>
          </template>
          <!-- END: Actions Column -->
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import formService from '@/services/form'
import listMixinFactory from '@/mixins/list'

export default {
  mixins: [
    listMixinFactory({
      routeName: 'forms',
      searchField: 'name',
      service: formService,

      tableColumns: [
        { key: 'internalTitle', label: 'Title', sortable: true },
        { key: 'internalDescription', label: 'Description', class: 'disabled' },
        { key: 'open', label: 'Accessibility', class: 'disabled text-center' },
        {
          key: 'numberOfBlocks',
          label: 'No. of Blocks',
          class: 'disabled text-center',
        },
        {
          key: 'numberOfResponses',
          label: 'No. of Responses',
          class: 'disabled text-center',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],

      sort: {
        name: 'name',
      },
    }),
  ],

  computed: {
    canCreate() {
      return this.$can('Create', 'Form')
    },

    canUpdate() {
      return this.$can('Update', 'Form')
    },

    canRead() {
      return this.$can('Read', 'Form')
    },

    canDelete() {
      return this.$can('Delete', 'Form')
    },
  },

  methods: {
    canDeleteForm(form) {
      return this.canDelete && form.numberOfResponses === 0
    },

    deleteTooltip(form) {
      if (this.canDeleteForm(form)) {
        return this.$t('Delete')
      }

      return form.numberOfResponses !== 0
        ? this.$t('form-with-response-delete-text')
        : this.$t("You don't have permission to perform delete Form")
    },

    confirmRemoval(item) {
      if (!this.canDeleteForm(item)) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(
        async result => {
          if (result.value) {
            await this.$async(
              formService.delete(item.uuidKey, { version: item.version }),
            )

            this.fetchList()
          }
        },
      )
    },

    create() {
      this.$router.push({ name: 'forms.create' })
    },
  },
}
</script>
